import Column from '../src/components/Column'
import React from 'react'

import { withTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import { Box, Typography } from '@mui/material'

const PageNotFound = ({ t }) => {
  const router = useRouter()

  return (
    <Box
      sx={{
        p: 2,
        flexGrow: 1,
        mr: 'auto',
        ml: 'auto',
        maxWidth: 420,
        overflowX: 'hidden',
        height: '100%',
      }}
    >
      <Column sx={{ mt: 2, width: '100%', alignItems: 'center' }}>
        <Typography variant='h4' color='inherit' sx={{ mt: 2 }}>
          404!
        </Typography>

        <Box
          src='/images/logo_undertext.png'
          onClick={() => router.push('/')}
          alt='logo'
          component='img'
          sx={{ width: 200, height: 200, m: 10, cursor: 'pointer' }}
        />

        <Typography
          variant='subtitle2'
          color='inherit'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('pageNotFound')}
        </Typography>
      </Column>
    </Box>
  )
}

export default withTranslation('common')(PageNotFound)
